// global styles
import "katex/dist/katex.min.css";
import "./src/styles/global.scss";
import "./src/styles/typography.scss";

// use smooth scrolling for anchor links
require("smooth-scroll")('a[href*="#"]');

// allow for some links to maintain scrolling
export const shouldUpdateScroll = ({ routerProps }) => {
  return !routerProps?.location?.state?.disableUpdateScroll;
};
